<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-img :src="require('../assets/comingSoon.png')" class="my-3" contain height="400"></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <h3 class="text-h3 text-center black--text">My Apps</h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <h4
          class="text-subtitle-1 text-center black--text"
        >I like to build applications. I'll have a few of them here soon.</h4>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "Jokes",
  data: () => ({
    forLater: []
  })
};
</script>

<style>
</style>
